$(document).ready(function () {
  const htmlAreaArray = $('.articleWrapper .articleWrapper__content div.row:nth-child(2) article').children()
  if (htmlAreaArray && htmlAreaArray.length) {
    for (let i = 0; i < htmlAreaArray.length; i++) {

      if (htmlAreaArray[i].nodeName === 'P') {
        $('.articleWrapper .articleWrapper__content div.row:nth-child(1) article.contentArticle').append(htmlAreaArray[i])
        break
      }
      $('.articleWrapper .articleWrapper__content div.row:nth-child(1) article.contentArticle').append(htmlAreaArray[i])
    }
  }
})
